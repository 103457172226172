import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/en"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import TextMosaic from "../components/ui-sections/text-mosaic"
import TextSection from "../components/ui-sections/text-section"
import ImageGallery from "../components/ui-sections/image-gallery"

const OrigensPage = (props) => {
	const heroText = "Origins";
	const sentenceText = "It all starts in June 1969";
	const titleAvi = "It all starts in June 1969";
	const titlePare = "The big step, keeping the essence";
	const titlePere = "The gastronomic touchprint of Pere";
	const contentAvi = "In June 1969, grandfather Pere and grandmother Carmeta inaugurated Cal Pere del Maset. The restaurant was unforgettable from day one by the Palau Sardà family, where the daughter celebrated her wedding on the opening day. And that grilled meat restaurant, run by the grandparents, and two little kids which were our father and our uncle, was increasing the capacity of the dining rooms, the kitchen, and consequently the menu. With a woodpecker they cooked shoulders, goats, rabbits and also were born the stews and oven dishes that we find today on the menu. Grandparents laid the foundations of what the restaurant is today. The foundations of three different generations to this day.";
	const contentPare = "The father took over from our grandfather, and him and our mother have made the restaurant a benchmark in Alt Penedès of cuisine of the best product. Between the 1980s and 2000s, the restaurant grew greatly with the creation of private dining rooms, the cellar, and the complete renovation of the kitchen and dining rooms. But keeping the soul, the essence of the first day. Cal Pere del Maset is a place where memories of all kinds accumulate, generations of families who came with their grandparents and now come with their children. Good food and good drink, but also nostalgia and feelings. And this essence is what our father and our mother have always given to the restaurant with their personality. Following the legacy of grandparents, so that our customers, friends, known and unkown people come to eat and feel at home."
	const contentPere = "El Pere is the third generation, and today he leads, together with our mother, the day-to-day running of the restaurant. Growing up on embers and stoves, from a very young age he had always clear that he wanted to dedicate himself to cooking. He started toasting bread when he was only 9 years old, and grew up combining studies with always helping in the kitchen. After finishing his studies, he went to train at prestigious restaurants in the world of the Michelin Star, such as Les Crayères (Reims, France) or Chef Fermí Puig's Drolma in Barcelona. And today, for more than a decade now, it has maintained a seasonal gastronomic offer, combining the menu of a lifetime with tasting menus, seasonal dishes and pairings for the most demanding palates. And like our grandparents, our father and our mother, maintaining a close and familiar treatment."
	const textSectionText1 = "\"The origins are our grandparents. The style, the embers, the fire, the stews, the stir-fry, the toasts, the touch, the treatment, the effort and the sacrifice, the character, the push, the live, part of who we are and part of what will come. Our grandparents. Our dear grandfather Pere and our dear grandmother Carmeta.\""
	const textSectionText2 = "\"Never give up.\" From grandfather to dad and from dad to Pere and myself."


	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Origins"
	    	lang="en"
	    	description={textSectionText1}
	    />
		  <HeroImage 
		  	titleText={heroText} 
		  	sentenceText={sentenceText}
		  	image="casa_antiga" 
		  />
		  <TextSection textSectionText={textSectionText1} />
	    <StaticQuery
	      query={graphql`
	        # Replace this comment with the GraphQL query above
	        {
	          allOrigensImagesEnYaml {
	            edges {
	              node {
	                title
	                image {
	                	id
	                  childImageSharp {
	                    fluid {
	                      aspectRatio
	                      originalImg
	                      # Use gatsby-image later for better user experience
	                      ...GatsbyImageSharpFluid
	        }}}}}}}
	      `}
	      render={data => (
	        <ImageGallery
	          itemsPerRow={[2, 3]} // This will be changed to `[2, 3]` later
	          images={data.allOrigensImagesEnYaml.edges.map(({ node }) => ({
	          	id: node.image.id,
	            ...node.image.childImageSharp.fluid,
	            caption: `${node.title}`,
	          }))}
	        />
	      )}
	    />
	    <TextMosaic 
	    	titleAvi={titleAvi}
	    	titlePare={titlePare}
	    	titlePere={titlePere}
	    	contentAvi={contentAvi}
	    	contentPare={contentPare}
	    	contentPere={contentPere}
	    />
	    <TextSection textSectionText={textSectionText2} />
	    <HeroImage  image="paisatge" />
	  </Layout>
	)
}

export default OrigensPage
